import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import { useMap } from 'react-map-gl';

import { formatDateRange } from './dateFormat';

import './EventList.css';

function EventList(props) {
  let events = props.locations.features.reduce((events, location) => {
    return events.concat(location.properties.events);
  }, []).sort((a, b) => {
    let aDate = Date.parse(a.startDate || '2040-01-01');
    let bDate = Date.parse(b.startDate || '2040-01-01');
    if (aDate < bDate) return -1;
    if (aDate > bDate) return 1;
    return 0;
  })
  return (
    <div className="list-container p-2">
      {events.map((event) => {
        return (<EventItem key={event.urn} event={event} />)
      })}
    </div>
  );
}

function EventItem(props) {
  // const { eventMap } = useMap();

  // const onClick = () => {
  //   eventMap.flyTo({zoom: 8, center: [props.event.longitude, props.event.latitude]});
  // }

  const event = props.event;
  const title = event.name.indexOf(event.brand) === -1 ? `${event.brand} ${event.name}` : event.name;

  return (
    <Card className="mb-3">
      <Row className="g-0">
        <Col md={3}>
          { event.imageUrl ? 
            <img src={event.imageUrl} className="img-fluid rounded-start card-img-top card-img-bottom" loading="lazy" alt="" /> :
            <></>
          }
        </Col>
        <Col md={9}>
          <Card.Body>
            <Card.Title>{title}</Card.Title>
            <h6>{formatDateRange(event.startDate, event.endDate)}</h6>
            <div className="mb-2">
              {event.eventTypes.split(',').map((eventType) => {
                return (<span key={eventType} className="badge bg-danger me-1">{eventType}</span>)
              })}
            </div>
            <div>
              <a href={event.url} className="btn btn-secondary btn-sm" target="_blank" rel="noreferrer">Register</a>
            </div>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  )
}

export default EventList;
