import { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";

import FilterBar from "./FilterBar";
import EventMap from "./EventMap";
import EventList from "./EventList";

import { MapProvider } from "react-map-gl";

function useSearchParamsState(defaultValue) {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamsState = searchParams.toString() !== '' ? searchParams : new URLSearchParams(defaultValue);
  const state = [...searchParamsState.entries()].reduce(
    (o, [key, value]) => ({ ...o, [key]: value }),
    {}
  );

  const setSearchParamsState = (newState) => {
    const next = Object.assign(
      {},
      state,
      newState
    );
    setSearchParams(next);
  };
  return [state, setSearchParamsState];
}

function distinct(value, index, self) {
  return self.indexOf(value) === index;
}

function filterLocations(locations, filter) {
  return locations.reduce((previous, location) => {
    let events = location.properties.events.filter((event) => {
      let endDate = new Date(Date.parse(event.endDate || '2040-01-01'));
      endDate.setDate(endDate.getDate() + 1);
      if (endDate.valueOf() < Date.now()) {
        return false;
      }
      if (filter?.eventType && event.brand !== filter?.eventType) {
        return false;
      }
      if (filter?.date && event.startDate.slice(0, filter?.date?.length) !== filter?.date) {
        return false;
      }
      return true;
    })
    if (events.length > 0) {
      const brands = events.map(event => event.brand).filter(distinct).join();
      previous.push(Object.assign({}, location, {
        properties: Object.assign({ icon: brands }, location.properties, { events })
      }));
    }
    return previous;
  }, []);
}

export default function EventDisplay() {
  const [filter, setFilter] = useSearchParamsState({ view: "map" });
  const [locations, setLocations] = useState({ type: "FeatureCollection", features: [] });

  const filteredLocations = { type: "FeatureCollection", features: filterLocations(locations.features, filter) };

  useEffect(() => {
    async function fetchData() {
      const response = await fetch('/events-geo.json');
      const json = await response.json();
      setLocations(json);
    }
    fetchData();
  }, []);

  return (
    <MapProvider>
      <FilterBar filter={filter} setFilter={setFilter} locations={locations} />
      {
        filter.view !== 'list' ?
          <EventMap locations={filteredLocations} filter={filter} /> :
          <EventList locations={filteredLocations} />
      }
    </MapProvider>
  );
}
