import Container from 'react-bootstrap/Container';
// import Image from 'react-bootstrap/Image'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import './Navigation.css';

const rssFeedReaders = [
  { name: 'Feedly', url: 'https://feedly.com/i/subscription/feed/%s' },
  { name: 'Inoreader', url: 'https://www.inoreader.com/feed/%s' },
  { name: 'NewsBlur', url: 'https://www.newsblur.com/?url=%s' },
];
const rssFeedUrl = `https://${process.env.REACT_APP_WEBSITE_HOSTNAME}/feeds/rss.xml`;

function Navigation() {
  return (
    <Navbar variant="dark" className="navbar-bg-dark" expand="lg" fixed="top">
      <Container fluid>
        <Navbar.Brand href="#">
          <img
            src={new URL(process.env.REACT_APP_WEBSITE_LOGO, `https://${process.env.REACT_APP_WEBSITE_HOSTNAME}/assets/logos/`).toString()}
            alt={process.env.REACT_APP_WEBSITE_NAME}
            height="30px"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse >
          <Nav className="me-auto">
            <Nav.Link href={process.env.REACT_APP_WEBSITE_FEEDBACK_URL} target="_blank">{process.env.REACT_APP_WEBSITE_FEEDBACK_TEXT}</Nav.Link>
            <NavDropdown title="Add to Calendar">
              <NavDropdown.Item href={`webcal://${process.env.REACT_APP_WEBSITE_HOSTNAME}/unbreakable.ics`}>Outlook/Apple Calendar</NavDropdown.Item>
              <NavDropdown.Item href={`https://calendar.google.com/calendar/u/0/r?cid=webcal://${process.env.REACT_APP_WEBSITE_HOSTNAME}/unbreakable.ics`} target="_blank">Google Calendar</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Subscribe">
              {
                rssFeedReaders.map(rssFeedReader =>
                  <NavDropdown.Item key={rssFeedReader.name} href={rssFeedReader.url.replace('%s', encodeURIComponent(rssFeedUrl))} target="_blank">{rssFeedReader.name}</NavDropdown.Item>
                )
              }
              <NavDropdown.Divider />
              <NavDropdown.Item href={rssFeedUrl}>Other RSS Feed Reader</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
