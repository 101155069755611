import React, { useState, useCallback } from 'react';
import { Map, Source, Layer, Popup, FullscreenControl, NavigationControl } from 'react-map-gl';
import mapboxgl from "mapbox-gl";

import './EventMap.css';
import { formatDateRange } from './dateFormat';
import { getMarkerImageData } from './MarkerImageService';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

// export const clusterLayer = {
//   id: 'clusters',
//   type: 'circle',
//   source: 'locations',
//   filter: ['has', 'point_count'],
//   paint: {
//     'circle-color': '#181818',
//     'circle-radius': 16,
//     'circle-stroke-width': 1,
//     'circle-stroke-color': '#fff'
//   }
// };

// export const clusterCountLayer = {
//   id: 'cluster-count',
//   type: 'symbol',
//   source: 'locations',
//   filter: ['has', 'point_count'],
//   layout: {
//     'text-field': '{point_count_abbreviated}',
//     'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
//     'text-size': 12
//   },
//   paint: {
//     'text-color': "#ffffff"
//   }
// };

export const unclusteredPointLayer = {
  id: 'unclustered-point',
  type: 'symbol',
  source: 'locations',
  // filter: ['!', ['has', 'point_count']],
  layout: {
    'icon-image': '{icon}',
    'icon-allow-overlap': true
  },
};

export default function EventMap(props) {
  const [popupInfo, setPopupInfo] = useState(null);
  const [cursor, setCursor] = useState('auto');

  const onMapClick = event => {
    const feature = event?.features[0] || null;
    const properties = feature?.properties || null;
    setPopupInfo(properties);
  }

  const mapRef = useCallback(node => {
    if (node != null) {
      const nativeMap = node.getMap();
      nativeMap.on('styleimagemissing', event => {
        const styleIds = event.id.split(',');
        const bitmap = getMarkerImageData(styleIds);
        nativeMap.addImage(event.id, bitmap, { pixelRatio: 4 });
      });
    }
  }, []);

  const onMouseEnter = React.useCallback(() => setCursor('pointer'), []);
  const onMouseLeave = React.useCallback(() => setCursor('auto'), []);

  return (
    <div className={`map-container ${process.env.REACT_APP_WEBSITE_EMBED ? "map-container-embed" : ""}`}>
      <Map
        ref={mapRef}
        id="eventMap"
        initialViewState={process.env.REACT_APP_WEBSITE_MAP_INITIAL_VIEW ? JSON.parse(process.env.REACT_APP_WEBSITE_MAP_INITIAL_VIEW) : {
          longitude: 0,
          latitude: 15,
          zoom: 1.25
        }}
        mapStyle={process.env.REACT_APP_WEBSITE_MAP_STYLE}
        mapboxAccessToken={process.env.REACT_APP_WEBSITE_MAP_TOKEN}
        onClick={onMapClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        cursor={cursor}
        interactiveLayerIds={["unclustered-point"]}
        customAttribution={'&copy; <a href="https://www.ocrbase.com/" target="_blank">ocrbase</a>'}
      >
        {!process.env.REACT_APP_WEBSITE_EMBED &&
          <FullscreenControl position="top-left" />
        }
        <NavigationControl position="top-left" showCompass={false} />
        <Source
          id="locations"
          type="geojson"
          data={props.locations}
        // cluster={true}
        // clusterMaxZoom={5}
        // clusterRadius={50}
        >
          {/* <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} /> */}
          <Layer {...unclusteredPointLayer} />
        </Source>
        {popupInfo && (
          <Popup
            closeOnClick={false} // onMapClick will handle this anyway
            longitude={Number(popupInfo.longitude)}
            latitude={Number(popupInfo.latitude)}
            onClose={() => setPopupInfo(null)}
          >
            <EventPopup properties={popupInfo} />
          </Popup>
        )}
      </Map>
    </div>
  );
}

function EventPopup(props) {
  const events = JSON.parse(props?.properties?.events || "[]");
  return (
    <>
      <h5>{props.name}</h5>
      {
        events.map(event => {
          const title = event.name.indexOf(event.brand) === -1 ? `${event.brand} ${event.name}` : event.name;
          const dates = formatDateRange(event.startDate, event.endDate);
          return (
            <div key={event.urn}>
              <a className="link-light" href={event.url} target="_blank" rel="noreferrer">
                <h6>{title}</h6>
              </a>
              <p>{dates}</p>
              <p>{event.eventTypes}</p>
            </div>
          );
        })
      }
    </>
  )
}
