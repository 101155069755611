const brandStyles = {
  "Spartan": { fillStyle: "#cf1019", strokeStyle: "#fff" },
  // spartansprint: { fillStyle: "rgb(207, 16, 25)", strokeStyle: "#fff" },
  // spartancity: { fillStyle: "#486D96", strokeStyle: "#fff" },
  // spartanstadion: { fillStyle: "#FDC32D", strokeStyle: "#fff" },
  // spartansuper: { fillStyle: "rgb(35, 97, 167)", strokeStyle: "#fff" },
  // spartanbeast: { fillStyle: "rgb(132, 189, 0)", strokeStyle: "#fff" },
  // spartanultra: { fillStyle: "#5C068C", strokeStyle: "#fff" },
  // spartankids: { fillStyle: "#ee7624", strokeStyle: "#fff" },
  // Named spartantrails to avoid clash with preloaded icons. Need to do for other brands
  "Spartan Trail": { fillStyle: "#333", strokeStyle: "#80c8bf" },
  // spartanhh4: { fillStyle: "#003B5C", strokeStyle: "#fff" },
  // spartanhh12: { fillStyle: "#4A4F54", strokeStyle: "#fff" },
  // spartanhh24: { fillStyle: "#979899", strokeStyle: "#fff" },
  "DEKA": { fillStyle: "#000", strokeStyle: "#fff" },
  "Tough Mudder": { fillStyle: "#fe5804", strokeStyle: "#fff" },
  // toughmudder5k: { fillStyle: "#d0de2c", strokeStyle: "#fff" },
  // toughmudder10k: { fillStyle: "#f89c22", strokeStyle: "#fff" },
  // toughmudder15k: { fillStyle: "#f15822", strokeStyle: "#fff" },
  // toughmudderinfinity: { fillStyle: "#722182", strokeStyle: "#fff" },
  // toughmuddertoughest: { fillStyle: "#19a7b2", strokeStyle: "#000" }, // ""#fe5804" },
  // toughmudderworlds: { fillStyle: "#000", strokeStyle: "#fe5804" },
  "Highlander": { fillStyle: "#374A5D", strokeStyle: "#D8DC9F" },
  "Molokai 2 Oahu": { fillStyle: "#1A3B6D", strokeStyle: "#fff" },
  "La Ruta": { fillStyle: "#F8D748", strokeStyle: "#000" },
  "Peak Races": { fillStyle: "rgb(5, 21, 38)", strokeStyle: "#bc1f26" },
  "Pretty Muddy": { fillStyle: "#ec008c", strokeStyle: "#fff" }, // strokeStyle: '#2e008b' },
  "Rat Race": { fillStyle: '#fc0', strokeStyle: "#fff" },
  "Inflatable 5K": { fillStyle: 'rgb(224 10 20)', strokeStyle: '#000' },
  "Savage Race": { fillStyle: '#499FD9', strokeStyle: '#fff' },
  "Rugged Maniac": { fillStyle: '#ba2f1f', strokeStyle: '#fff' },
  "Terrain Race": { fillStyle: '#1b1464', strokeStyle: '#fff' },
  "Muddy Dash": { fillStyle: 'rgb(117, 76, 36)', strokeStyle: '#fff' },
  "OCR World Championship": { fillStyle: "#87BA41", strokeStyle: "#000" },
  "MUDGIRL": { fillStyle: "rgb(231,33,102)", strokeStyle: "#fff" },
  "default": { fillStyle: "#181818", strokeStyle: "#fff" }
};

export function getMarkerImageData(styleIds) {
  let styles = styleIds.map(styleId => brandStyles[styleId] || brandStyles.default);
  let width = 96, height = 96;
  let canvas = getCanvas(width, height);
  let ctx = canvas.getContext('2d');
  drawIcon(ctx, width, height, styles);
  let imageData = ctx.getImageData(0, 0, width, height);
  return imageData;
}

function getCanvas(width, height) {
  if (typeof(OffscreenCanvas) == 'function') {
    return new OffscreenCanvas(width, height);
  }
  let canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  return canvas
}

function drawIcon(ctx, width, height, styles) {
  ctx.lineWidth = height / 12;

  let arc = (Math.PI * 2) / styles.length;
  for (let i = 0; i < styles.length; i++) {
    let style = styles[i];
    Object.assign(ctx, style);
    drawSlice(ctx, width / 2, height / 2, width / 2, (Math.PI * 0.5) + (arc * i), (Math.PI * 0.5) + (arc * (i + 1)));
    drawArc(ctx, width / 2, height / 2, width * 0.35, (Math.PI * 0.5) + (arc * i), (Math.PI * 0.5) + (arc * (i + 1)));
  }
}

function drawSlice(ctx, x, y, radius, startAngle, endAngle) {
  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.arc(x, y, radius, startAngle, endAngle);
  ctx.closePath();
  ctx.fill();
}

function drawArc(ctx, x, y, radius, startAngle, endAngle) {
  ctx.beginPath();
  ctx.arc(x, y, radius, startAngle, endAngle);
  ctx.stroke();
}
