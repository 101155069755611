export function formatDateRange(start, end) {
  if (!start || !end) return 'TBD';
  const startDate = parseISOLocalDate(start).toLocaleDateString([], { dateStyle: "medium" });
  const endDate = parseISOLocalDate(end).toLocaleDateString([], { dateStyle: "medium" });
  return startDate === endDate ? startDate : `${startDate} - ${endDate}`;  
}

export function parseISOLocalDate(s) {
  var b = s.split(/\D/);
  return new Date(b[0], b[1]-1, b[2], 0, 0, 0);
}
