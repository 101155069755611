import { Outlet } from "react-router-dom";

import Navigation from "../Navigation";

import './root.css';

export default function Root() {
  return (
    <div className="full-height">
      {!process.env.REACT_APP_WEBSITE_EMBED &&
        <Navigation />
      }
      <Outlet />
    </div>
  );
}
