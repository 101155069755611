import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import './FilterBar.css';

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const brandOrder = process.env.REACT_APP_WEBSITE_ORGANIZERS ? process.env.REACT_APP_WEBSITE_ORGANIZERS.split(',') : [];

function FilterBar(props) {
  function setEventType(event) {
    props.setFilter(Object.assign({}, props.filter, { eventType: event.target.value }));
  }

  function setDate(event) {
    props.setFilter(Object.assign({}, props.filter, { date: event.target.value }));
  }

  function setView(event) {
    props.setFilter({ view: event.target.value });
  }

  const now = new Date();
  let dateOptions = [
    {
      value: now.getUTCFullYear(),
      name: "Until End " + now.getUTCFullYear(),
    },
    {
      value: now.getUTCFullYear() + 1,
      name: "All " + (now.getUTCFullYear() + 1),
    },
  ]
  dateOptions = dateOptions.concat([...Array(12).keys()].map(month => {
    let date = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + month, 1, 0, 0, 0));
    return {
      value: date.toISOString().slice(0, 7),
      name: monthNames[date.getUTCMonth()] + ' ' + date.getUTCFullYear()
    };
  }));


  const additionalBrandOptions = props.locations.features.reduce((options, location) => {
    for (const event of location.properties.events) {
      if (!(options.includes(event.brand) || brandOrder.includes(event.brand))) {
        options.push(event.brand);
      }
    }
    return options
  }, []);
  const brandOptions = brandOrder.concat(additionalBrandOptions.sort());

  const viewOptions = [
    { name: 'Map', value: 'map' },
    { name: 'List', value: 'list' },
  ];

  return (
    <div className={`filterbar ${process.env.REACT_APP_WEBSITE_EMBED ? "filterbar-embed" : ""}`}>
      <form className="row row-cols-sm-auto g-3 align-items-center mt-0">
        <div className="col-4 mt-1 ps-3">
          <label className="visually-hidden" htmlFor="eventType">Event Type</label>
          <Form.Select size="sm" id="eventType" value={props.filter.eventType} onChange={setEventType}>
            <option value="">All Events</option>
            {brandOptions.map((option) => {
              return (<option key={option}>{option}</option>)
            })}
          </Form.Select>
        </div>
        <div className="col-4 mt-1 pe-3">
          <label className="visually-hidden" htmlFor="eventType">Date</label>
          <Form.Select size="sm" id="eventType" value={props.filter.date} onChange={setDate}>
            <option value="">Any Date</option>
            {dateOptions.map((dateOption) => {
              return (<option key={dateOption.value} value={dateOption.value}>{dateOption.name}</option>)
            })}
          </Form.Select>
        </div>
        <div className="col-4 mt-1 pe-3 text-end">
          {!process.env.REACT_APP_WEBSITE_EMBED &&
            <ButtonGroup size="sm" aria-label="View">
              {viewOptions.map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  id={`radio-${idx}`}
                  type="radio"
                  variant="dark"
                  name="view"
                  value={radio.value}
                  checked={props.filter.view === radio.value}
                  onChange={setView}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          }
        </div>
      </form>
    </div>
  )
}

export default FilterBar;
